const readmoreToggle = () => { 

    const readmore = document.querySelectorAll('.readmore-toggle');
    readmore.forEach(element => {
        element.addEventListener('click',(e) =>{
            if(!element.classList.contains('collapsed')){
                element.querySelector('span').innerHTML = 'Leggi di meno'
            }else{
                element.querySelector('span').innerHTML = 'Leggi di più'
            }
        });
        
    });
        
};
export default readmoreToggle;