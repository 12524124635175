const StickyNav = () => {

    let navbar = document.querySelector(".header-sticky");
    if (navbar == null) return;

    let lastKnownScrollPosition = 0;

    window.addEventListener('scroll', function() {
        lastKnownScrollPosition = window.scrollY;

        let stickyOffset = navbar.offsetTop;

        if (lastKnownScrollPosition >= navbar.offsetHeight) {
            navbar.classList.add("sticky-top")
        } else {
            navbar.classList.remove("sticky-top");
        }
    });

}

export default StickyNav;