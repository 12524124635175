
const megamenuTriggers = () => {
    let toggles =  document.querySelectorAll('.dropdown-toggle');
    toggles.forEach(toggle => {
        toggle.addEventListener('click', () => {
            if(toggle.classList.contains('show')){
                document.body.classList.add('menu_opened');
            }else{
                document.body.classList.remove('menu_opened');
            }
        })
    });
    window.addEventListener('mouseup', function(e){  
        let menu =  document.querySelector('.hamburger_menu');
        const classNames = ['dropdown-toggle','show']
        if (document.body.classList.contains('menu_opened') && !e.target.classList.contains(classNames) && !menu.contains(e.target)){
            document.body.classList.remove('menu_opened');
        }
    });
}

const hamburgerTrigger = () => {
    let triggers =  document.querySelectorAll('.hamburger_menu_trigger');
    let menu =  document.querySelector('.hamburger_menu');
    let stickycta = document.querySelector('.site_sticky__btn');
    
    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            menu.classList.toggle('show');
            document.body.classList.toggle('menu_opened');
            document.body.classList.toggle('hambuger_opened');
            if(window.innerWidth < 768){
                if ( stickycta ) {
                    stickycta.classList.toggle('d-none');
                }
                
            }
        });
    });
}

const mobileMenu = () => {
    let triggers =  document.querySelectorAll('[data-submenu]');
    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            let attr = trigger.dataset.submenu;
            let submenu = document.getElementById(attr);
            if( submenu.classList.contains('--2')){
                trigger.closest('.lvl').classList.add('hide','left');
            }else{
                trigger.closest('.lvl').classList.add('hide');
            }            
            submenu.classList.remove('hide','left');
        })
    })
}



const menuFunctions = () => {
    if(document.querySelector(".site_header")){
        megamenuTriggers();
    }    
    if(document.querySelector(".hamburger_menu")){
        hamburgerTrigger();
        mobileMenu(); 
    }   
}

export default menuFunctions;