import * as bootstrap from 'bootstrap';

const Resources = () => {
    const resources = document.querySelectorAll('.resource_check');
    const resourceModal = new bootstrap.Modal(document.getElementById('resourceModal'));
    
    const popupsub = document.querySelectorAll('.popupsub');

    popupsub.forEach(item => {
        item.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            resourceModal.show();
            
            let m = document.getElementById('resourceModal');
            m.addEventListener('hidden.bs.modal', function (e) {
                if ( document.cookie.indexOf('quantycatoken=') != -1 ) location.reload();
            });
        });
    });

    resources.forEach(resource => {
        resource.addEventListener('click', function handleClick(event) {
            event.preventDefault();
            console.log('resource clicked', event);
            
            let id = event.target.dataset.id;
            console.log(id);

            if ( id ) {

                const data = new FormData();
                data.append( 'action', 'check_resources' );
                data.append( 'nonce', socialidea.nonce );
                data.append( 'resourceid', id );

                fetch( socialidea.AJAX_URL, {
                    method: "POST",
                    body: data,
                })
                .then(function (response) {
                    return response.json();
                }) 
                .then(function (data) {
                    if ( data.success ) {
                        let url = data.data.url;
                        let popup_window=window.open(url,'_blank');            
                        try {
                            popup_window.focus();   
                        } catch (e) {
                            alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                        }
                    }else{
                        resourceModal.show();
                    }
                })
                .catch(function (error){
                    // Catch errors
                    console.log(error);
                    resourceModal.show();
                });

            }else{
                resourceModal.show();
            }

        });
    });

}

export default Resources;