import * as bootstrap from 'bootstrap';
const resourcesFilter = () => {
    let endpoint = '';
	let lang = socialidea.LANG ? socialidea.LANG : 'it';
    endpoint = '/wp-json/api/v1/resources/?lang='+lang;

	if ( document.getElementById('resourcesFilter') ) {
		var app = new Vue({
			el: '#resourcesFilter',
			data() {
				return {
					resources : [],
					selectedType : '',
					selectedIndustry : [],
					selectedSolution : [],
					selectedTitle : '',
					selectedOrder:	'Data più recente',
					page: 1,
					perPage: 12,
					pages: [],	
					totalResources : '',
					loading: true,
				}
			},
			mounted(){
				fetch( endpoint )
				.then(res => res.json())
				.then(data => {
					this.resources = data;
					this.loading = false;
					function padTo2Digits(num) {
						return num.toString().padStart(2, '0');
					}
					function formatDate(date) {
						return [
							date.getFullYear(),
							padTo2Digits(date.getMonth() + 1),
							padTo2Digits(date.getDate()),
						].join('');
					}
					this.resources.forEach((resource) => {
						var reFormattedDate = resource.resource_date.split("/").reverse().join("-");
						var resourceDate = formatDate(new Date(reFormattedDate ))
						resource['formattedDate'] = resourceDate
					})
					this.resources.sort(function(a,b){
						var timeA = a.formattedDate;
						var timeB = b.formattedDate;
						return timeB - timeA
					});
				})
				
			},
			methods: {
				toggleDrop(e){
					let filter = document.querySelectorAll(".filter");			
					if(!e.target.classList.contains('fa-xmark')){	
						let isVisible = e.target.closest('.filter').classList.contains('opened');
						filter.forEach(el => {
							el.closest('.filter').classList.remove('opened');
						});	

						e.target.closest(".filter").classList.toggle('opened',!isVisible);			
						
						window.addEventListener('mouseup', function(e){   
							filter.forEach(el => {
								if (!el.contains(e.target) && el.closest(".filter").classList.contains('opened')){
									el.closest(".filter").classList.remove('opened');
								}
							});
							
						});
					}
				},
				fromSelectFilter(selectedFilter, selectedValue, event){
					switch (selectedFilter) {
						case 'industry':
							if(event.target.checked){
								if(!this.selectedIndustry.includes(selectedValue)){
									this.selectedIndustry.push(selectedValue);
								}
							}else{
								const index = this.selectedIndustry.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedIndustry.splice(index, 1); 
								}
							}
							break;
						case 'solution':
							if(event.target.checked){
								if(!this.selectedSolution.includes(selectedValue)){
									this.selectedSolution.push(selectedValue);
								}
							}else{
								const index = this.selectedSolution.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedSolution.splice(index, 1); 
								}
							}
							break;
						case 'title':
							this.selectedTitle = selectedValue;
							break;
					}
				},
				resetFilter(selectedFilter,event){
					let fiterParent = event.target.closest('.filter');
					let inputs = fiterParent.querySelectorAll('.filter__list > ul > li > input[type="checkbox"]');
					inputs.forEach(input =>{
						if(input.checked) input.checked = false;				
					})
					switch (selectedFilter) {
						case 'type':
							this.selectedType = [];
							break;
						case 'industry':
							this.selectedIndustry = [];
							break;
						case 'solution':
							this.selectedSolution = [];
							break;
						case 'title':
							this.selectedTitle = '';
							break;
					}
				},
				highlightResult(current, filter) {
					const startIndex = current.toLowerCase().indexOf(filter.toLowerCase())
					if (startIndex >= 0) {
						const endIndex = startIndex + filter.length
						const highlightedName = current.slice(0, startIndex) + '<span class="fw-500">' + current.slice(startIndex, endIndex) + '</span>' + current.slice(endIndex)
						return highlightedName
					}
					return current
				},
				paginate (posts) {
					let page = this.page;
					let perPage = this.perPage;
					let from = (page * perPage) - perPage;
					let to = (page * perPage);
					return  posts.slice(from, to);
				},
				setPages() {		
					let numberOfPages = Math.ceil(this.totalResources / this.perPage);
					this.pages = []; /* svuoto e ri-riempio */
					for (let index = 1; index <= numberOfPages; index++) {
						this.pages.push(index);
					}
				},
				sortResources(key){	
					if(key == 'Data più recente'){
						this.selectedOrder = 'Data più recente';
						this.paginatedResources.sort(function(a,b){
							var timeA = a.formattedDate;
							var timeB = b.formattedDate;
							return timeB - timeA
						});
					} else {
						this.selectedOrder = 'Data meno recente';
						this.paginatedResources.sort(function(a,b){
							var timeA = a.formattedDate;
							var timeB = b.formattedDate;
							return timeA - timeB
						});
					}
				},
				ResourcesClick(event){
					const resourceModal = new bootstrap.Modal(document.getElementById('resourceModal'));
					event.preventDefault();		
		
					let id = event.currentTarget.dataset.id;
					if ( id ) {	
						const data = new FormData();
						data.append( 'action', 'check_resources' );
						data.append( 'nonce', socialidea.nonce );
						data.append( 'resourceid', id );
						fetch( socialidea.AJAX_URL, {
							method: "POST",
							body: data,
						})
						.then(function (response) {
							return response.json();
						}) 
						.then(function (data) {
							if ( data.success ) {
								let url = data.data.url;
								//window.open(url, '_blank');
								// setTimeout(() => {
								//     window.open(url, '_blank');
								// })
								let popup_window=window.open(url,'_blank');            
								try {
									popup_window.focus();   
								} catch (e) {
									alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
								}
							}else{
								resourceModal.show();
							}
						})
						.catch(function (error){
							// Catch errors
							console.log(error);
							resourceModal.show();
						});
		
					}else{
						resourceModal.show();
					}

				},
				paginationClick(event){
					let filterTop = document.querySelector('#resourcesFilter').offsetTop;
					if(!event.target.classList.contains('pagination-current')){
						window.scroll({
							top: filterTop, 
							left: 0,
							behavior: 'smooth'
						  });
					}
				}
			},
			computed: {
				resourcesTitle(){
					let title = [];
					this.computedResources.forEach(resource => {
						if(resource.title != null){
							if(!title.includes(resource.title) ){
								title.push(resource.title);
							}
						}
					});
					title.sort();
					return title;
				},
				resourcesType(){
					let typeArr = [];
					this.resources.forEach(resource => {
						if(resource.type.length){
							resource.type.forEach(el =>{
                                typeArr.push(el);
                            })	
						}
					});
					let uniqueArray = typeArr.filter((item, index) => {
						return typeArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				resourcesIndustry(){
					let industryArr = [];
					this.resources.forEach(resource => {
						if(resource.industry.length){
                            resource.industry.forEach(el =>{
                                industryArr.push(el);
                            })
						};
					});
					let uniqueArray = industryArr.filter((item, index) => {
						return industryArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
                resourcesSolution(){
					let solutionArr = [];
					this.resources.forEach(resource => {
						if(resource.solution.length){
                            resource.solution.forEach(el =>{
                                solutionArr.push(el);
                            })
						};
					});
					let uniqueArray = solutionArr.filter((item, index) => {
						return solutionArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				paginatedResources(){
					this.totalResources = this.computedResources.length;
					return this.paginate(this.computedResources);
				},
				computedResources(){		
					this.page = 1;						
					let mainFilters = this.resources.filter(
						(resource) => {	
							let arrTypeTitle = resource.type.map(el => el.title)
							var title 		= this.selectedTitle ? (resource.title.toLowerCase().includes(this.selectedTitle.toLowerCase())) : true
							var type 		= this.selectedType ? arrTypeTitle.includes(this.selectedType.title) : true
							return title && type
						}
					)
					if(this.selectedIndustry.length || this.selectedSolution.length){
						let arrIndustry = this.selectedIndustry.map(el => el.title)
						let arrSolution = this.selectedSolution.map(el => el.title)
                        return mainFilters.filter(el =>{
                            let arrIndustryTitle = el.industry.map(el => el.title)
                            let arrSolutionTitle = el.solution.map(el => el.title)

							let resultIndustries 	= arrIndustry.some(value => arrIndustryTitle.includes(value))
                            let resultSolutions 	= arrSolution.some(value => arrSolutionTitle.includes(value));
                            return resultIndustries || resultSolutions
                        })
                    }else{
                        return mainFilters
                    }
				}		
			},
			watch: { 
				totalResources() {
					this.setPages(); /* ogni volta che totalResources cambia esegi setPages */
				}
			},
		})
	}
}

export default resourcesFilter;