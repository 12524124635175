const MobileFix = () => {

	const breakpoint = window.matchMedia( '(min-width:992px)' );

    let cards_people = document.querySelectorAll('.card_people');

    const breakpointChecker = function() {
        if ( breakpoint.matches === true ) {
            return;            
        } else {
            if ( cards_people.length > 0 ) {
                cards_people.forEach(element => {
                    // element.addEventListener('touchmove',function(e) {
                    //     e.preventDefault();
                    //     element.classList.remove('active');
                    // });
                    element.addEventListener('touchstart',function(e) {
                        //e.preventDefault();
                        element.classList.toggle('active');
                    });
                    // element.addEventListener('click',function(e) {
                    //     //e.preventDefault();
                    //     element.classList.toggle('active');
                    // });
                });
                
            }
        }
    };

    breakpoint.addEventListener('change', function (e){
        // kickstart
        breakpointChecker();
    })
    breakpointChecker();
}
export default MobileFix;