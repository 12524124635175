const scrollDown = () => {
    let scrollBtn = document.querySelector('.scrolling-img__scrolldown');
    
    if(scrollBtn){
        let pageHeadingHeight = document.querySelector('.scrolling-img').offsetHeight;
        let pageHeadingOffsetTop = document.querySelector('.scrolling-img').offsetTop;
        let siteHeaderHeight = document.querySelector('.site_header').offsetHeight;
        let stickyMenu = document.querySelector('.sticky-menu');
        
        if(stickyMenu){
            siteHeaderHeight = siteHeaderHeight + stickyMenu.offsetHeight;
        }

        scrollBtn.addEventListener("click", () => {
            window.scrollTo({
                top: pageHeadingOffsetTop + pageHeadingHeight - siteHeaderHeight,
                behavior: "smooth"
            });
        });
    }
    
}

export default scrollDown;