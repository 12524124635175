const industryTabs = () => {
    let cards = document.querySelectorAll('.main_card.--industry')
    let defaultTabs = document.querySelector('.industry_tabs__tabs_item.--default')

    cards.forEach(card => {
        card.addEventListener('click', (e) =>{
            let checkClass = false
            cards.forEach(el => {
                el.querySelector('.show_phrase').classList.remove('d-none')
                el.querySelector('.hide_phrase').classList.add('d-none')
            });
            if (!card.classList.contains('collapsed')) {
                checkClass = true;
                let pageHeadingHeight = document.querySelector('.industry_tabs__wrapper').offsetHeight;
                let pageHeadingOffsetTop = document.querySelector('.industry_tabs__wrapper').offsetTop;
                let siteHeaderHeight = document.querySelector('.site_header').offsetHeight;
                window.scrollTo({
                    top: pageHeadingOffsetTop + pageHeadingHeight - siteHeaderHeight,
                    behavior: "smooth"
                });
                card.querySelector('.show_phrase').classList.add('d-none');
                card.querySelector('.hide_phrase').classList.remove('d-none');
            }
            defaultTabs.classList.toggle('d-none', checkClass)
        })
        
    });
    
}

export default industryTabs;