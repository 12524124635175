const eventsFilter = () => {
    let endpoint = '';
	let lang = socialidea.LANG ? socialidea.LANG : 'it';
    endpoint = '/wp-json/api/v1/events/?lang='+lang;
	if ( document.getElementById('eventsFilter') ) {
		var app = new Vue({
			el: '#eventsFilter',
			data() {
				return {
					events : [],
					selectedType : [],
					selectedIndustry : [],
					selectedSolution : [],
					selectedTitle : '',
					selectedTimeType: '',
					page: 1,
					perPage: 12,
					pages: [],	
					totalEvents : '',
					loading: true,
				}
			},
			mounted(){
				fetch( endpoint )
				.then(res => res.json())
				.then(data => {
					this.events = data;
					this.loading = false;
					function padTo2Digits(num) {
						return num.toString().padStart(2, '0');
					}
					function formatDate(date) {
						return [
							date.getFullYear(),
							padTo2Digits(date.getMonth() + 1),
							padTo2Digits(date.getDate()),
						].join('');
					}
					var ToDay = formatDate(new Date());

					this.events.forEach((event) => {
						var reFormattedDate = event.event_date.split("/").reverse().join("-");
						var eventDate = formatDate(new Date(reFormattedDate ))
						event['formattedDate'] = eventDate
						if (eventDate >= ToDay) {
							event['timeType'] = 'future'
							if(eventDate == ToDay) event['today'] = true
						}else{
							event['timeType'] = 'past'
						}
					})
					this.events.sort(function(a,b){
						var timeA = a.formattedDate;
						var timeB = b.formattedDate;
						return timeB - timeA
					});
				})
				
			},
			methods: {
				toggleDrop(e){
					let filter = document.querySelectorAll(".filter");		
					if(!e.target.classList.contains('fa-xmark')){
						let isVisible = e.target.closest('.filter').classList.contains('opened');
						filter.forEach(el => {
							el.closest('.filter').classList.remove('opened');
						});	

						e.target.closest(".filter").classList.toggle('opened',!isVisible);			
						window.addEventListener('mouseup', function(e){   
							filter.forEach(el => {
								if (!el.contains(e.target) && el.closest(".filter").classList.contains('opened')){
									el.closest(".filter").classList.remove('opened');
								}
							});
						});
					}
				},
				fromSelectFilter(selectedFilter, selectedValue, event){
					switch (selectedFilter) {
						case 'type':
							if(event.target.checked){
								if(!this.selectedType.includes(selectedValue)){
									this.selectedType.push(selectedValue);
								}
							}else{
								const index = this.selectedType.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedType.splice(index, 1); 
								}
							}
							break;
						case 'industry':
							if(event.target.checked){
								if(!this.selectedIndustry.includes(selectedValue)){
									this.selectedIndustry.push(selectedValue);
								}
							}else{
								const index = this.selectedIndustry.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedIndustry.splice(index, 1); 
								}
							}
							break;
						case 'solution':
							if(event.target.checked){
								if(!this.selectedSolution.includes(selectedValue)){
									this.selectedSolution.push(selectedValue);
								}
							}else{
								const index = this.selectedSolution.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedSolution.splice(index, 1); 
								}
							}
							break;
						case 'title':
							this.selectedTitle = selectedValue;
							break;
					}
				},
				resetFilter(selectedFilter,event){
					let fiterParent = event.target.closest('.filter');
					let inputs = fiterParent.querySelectorAll('.filter__list > ul > li > input[type="checkbox"]');
					inputs.forEach(input =>{
						if(input.checked) input.checked = false;				
					})
					switch (selectedFilter) {
						case 'type':
							this.selectedType = [];
							break;
						case 'industry':
							this.selectedIndustry = [];
							break;
						case 'solution':
							this.selectedSolution = [];
							break;
						case 'title':
							this.selectedTitle = '';
							break;
					}
				},
				highlightResult(current, filter) {
					const startIndex = current.toLowerCase().indexOf(filter.toLowerCase())
					if (startIndex >= 0) {
						const endIndex = startIndex + filter.length
						const highlightedName = current.slice(0, startIndex) + '<span class="fw-500">' + current.slice(startIndex, endIndex) + '</span>' + current.slice(endIndex)
						return highlightedName
					}
					return current
				},
				paginate (posts) {
					let page = this.page;
					let perPage = this.perPage;
					let from = (page * perPage) - perPage;
					let to = (page * perPage);
					return  posts.slice(from, to);
				},
				setPages() {
					let numberOfPages = Math.ceil(this.totalEvents / this.perPage);
					this.pages = []; /* svuoto e ri-riempio */
					for (let index = 1; index <= numberOfPages; index++) {
						this.pages.push(index);
					}
				},
				paginationClick(event){
					console.log(event.target);
					let filterTop = document.querySelector('#eventsFilter').offsetTop;
					if(!event.target.classList.contains('pagination-current')){
						window.scroll({
							top: filterTop, 
							left: 0,
							behavior: 'smooth'
						  });
					}
				}
			},
			computed: {
				eventsTitle(){
					let title = [];
					this.computedEvents.forEach(event => {
						if(event.title != null){
							if(!title.includes(event.title) ){
								title.push(event.title);
							}
						}
					});
					title.sort();
					return title;
				},
				eventsType(){
					let typeArr = [];
					this.events.forEach(resource => {
						if(resource.type.length){
							resource.type.forEach(el =>{
                                typeArr.push(el);
                            })	
						}
					});
					let uniqueArray = typeArr.filter((item, index) => {
						return typeArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				eventsIndustry(){
					let industryArr = [];
					this.events.forEach(resource => {
						if(resource.industry.length){
                            resource.industry.forEach(el =>{
                                industryArr.push(el);
                            })
						};
					});
					let uniqueArray = industryArr.filter((item, index) => {
						return industryArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
                eventsSolution(){
					let solutionArr = [];
					this.events.forEach(resource => {
						if(resource.solution.length){
                            resource.solution.forEach(el =>{
                                solutionArr.push(el);
                            })
						};
					});
					let uniqueArray = solutionArr.filter((item, index) => {
						return solutionArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				paginatedEvents(){
					this.totalEvents = this.computedEvents.length;
					return this.paginate(this.computedEvents);
				},
				computedEvents(){		
					this.page = 1;							
					let mainFilters = this.events.filter(
						(event) => {	
							var title 		= this.selectedTitle ? (event.title.toLowerCase().includes(this.selectedTitle.toLowerCase())) : true
							var timeType 	= this.selectedTimeType ? (event.timeType == this.selectedTimeType) : true
							return title && timeType
						}
					)

					if(this.selectedType.length || this.selectedIndustry.length || this.selectedSolution.length){
						let arrType 	= this.selectedType.map(el => el.title)
						let arrIndustry = this.selectedIndustry.map(el => el.title)
						let arrSolution = this.selectedSolution.map(el => el.title)
                        return mainFilters.filter(el =>{
                            let arrTypeTitle 	 = el.type.map(el => el.title)
                            let arrIndustryTitle = el.industry.map(el => el.title)
                            let arrSolutionTitle = el.solution.map(el => el.title)

							let resultType 	= arrType.some(value => arrTypeTitle.includes(value))
							let resultIndustries 	= arrIndustry.some(value => arrIndustryTitle.includes(value))
                            let resultSolutions 	= arrSolution.some(value => arrSolutionTitle.includes(value));
                            return resultType ||resultIndustries || resultSolutions
                        })
                    }else{
                        return mainFilters
                    }
					
				}		
			},
			watch: { 
				totalEvents() {
					this.setPages(); /* ogni volta che totalEvents cambia esegi setPages */
				}
			},
		})
	}
}

export default eventsFilter;