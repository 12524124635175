const filtersToggle = () => {
    var toggles = document.querySelectorAll('.filter__input');
    toggles.forEach(toggle => {
        toggle.addEventListener('click', (e) =>{
            var filterParent = toggle.closest('.filter');
            filterParent.classList.toggle('opened');
        })
    });
   
}

export default filtersToggle;