import * as bootstrap from 'bootstrap';
// import LocomotiveScroll from 'locomotive-scroll';
import Sticky from 'sticky-js';
import 'fslightbox';

import scrollbarWidth from './utils/scrollbarWidth';

import HomeSlider from './components/homeslider';
import IndustrySlider from  './components/industryslider';
import StickyNav from  './components/StickyNav';
import BackToTop from './components/BackToTop';
import scrollDown from './components/scrollDown';
import PreLoader from './components/PreLoader';
import cardsCarousel from './components/cardscarousel';
import Swipers from './components/swipers';
import menuFunctions from './components/menuFunctions';

import mobileFix from './components/mobileFix';
import readmoreToggle from './components/readmoreToggle';
import industryTabs from './components/industryTabs';
import infiniteCarousel from './components/infiniteCarousel';
import filtersToggle from './components/filtersToggle';
import Resources from './components/Resources';
import newsletterBgFixer from './components/newsletterBgFixer';
import tecnologyNav from './components/tecnologyNav';
import stickyEventCta from './components/stickyEventCta';

/* VUEJS Filter */
import eventsFilter from './components/eventsFilter';
import resourcesFilter from './components/resourcesFilter';
import successStoriesFilter from './components/successStoriesFilter';
import useCasesFilter from './components/useCasesFilter';

document.addEventListener("DOMContentLoaded", function() {
    
    // const scroll = new LocomotiveScroll({
    //     el: document.querySelector('[data-scroll-container]'),
    //     smooth: true,
    //     // offset: [0, 0],
    //     //         getSpeed: true,
    //     //         getDirection: true,
    //     //         tablet: {
    //     //             breakpoint: 1080
    //     //         }
    // });
    
    // scroll.on('scroll', (args) => {
    //     // Get all current elements : args.currentElements
    //     if(typeof args.currentElements['hey'] === 'object') {
    //         let progress = args.currentElements['hey'].progress;
    //         console.log(progress);
    //         // ouput log example: 0.34
    //         // gsap example : myGsapAnimation.progress(progress);
    //     }
    // });
    scrollbarWidth();
    HomeSlider();
    IndustrySlider();
    StickyNav();
    BackToTop();
    scrollDown();
    PreLoader();
    Swipers();
    menuFunctions();
    readmoreToggle();
    Resources();

    if(document.querySelector('.industry_tabs')){
        industryTabs();
    }
    if(document.querySelector('.people_infinite_carousel')){
        infiniteCarousel();
    }
    if(document.querySelector('.filter')){
        filtersToggle();
    }
    if(window.innerWidth > 768){
        new Sticky('.sticky_form');
    }
    cardsCarousel();
    
    if(document.querySelector('.site_prefooter ')){
        newsletterBgFixer();
    }
    if(document.querySelector('.tecnology_nav__list ')){
        tecnologyNav();
    }
    /* if(document.querySelector('.event_sticky_cta ')){
        stickyEventCta();
    } */
    
    setTimeout(() => {
        refreshFsLightbox();
    }, 500);

    /* VUEJS Filter */
    eventsFilter();
    resourcesFilter();
    successStoriesFilter();
    useCasesFilter();
    mobileFix();

});