const PreLoader = () => {

    let preloader = document.getElementById("preloader");
    if (preloader == null) return;


    window.addEventListener('load', function() {
        setTimeout( () => {
            document.body.classList.remove("overflow-hidden");
            preloader.remove();
        },
        500);
        
    });

}

export default PreLoader;