const tecnologyNav = () => { 

    const links = document.querySelectorAll('.tecnology_nav__list li a');
    const nav = document.querySelector('.tecnology_nav__list');
    const title = document.querySelector('.tecnology_nav__title');
    if(window.innerWidth < 768){
        links.forEach(link => {
            link.addEventListener('click',() =>{
                nav.classList.remove('show')
                title.classList.add('collapsed')
            });
        });
    }
    
        
};
export default tecnologyNav;