import Swiper, {Scrollbar, Pagination} from 'swiper';

const Swipers = () => {

	const breakpoint = window.matchMedia( '(min-width:992px)' );
    // keep track of swiper instances to destroy later
    let allSliders;
    const breakpointChecker = function() {
        if ( breakpoint.matches === true ) {
            if ( allSliders !== undefined ){
				if ( allSliders.length ) {
					allSliders.forEach(
						element => {
							element.destroy( true, true );
						}
					)
				}else{
					allSliders.destroy(true,true);
				}
            }
            return;            
        } else {
            if  ( document.querySelector('.mobile-carousel') ) {
                mobileCarousel();
            }
        }
    };
	let cards_people = document.querySelectorAll('.card_people');
	const mobileCarousel = function() {
		allSliders = new Swiper('.mobile-carousel', {
			modules: [Scrollbar],
			spaceBetween: 10,
			slidesPerView: 1,
			freeMode: true,
			loop: false,
			scrollbar: {
				el: '.swiper-scrollbar',
				hide: false,
				draggable: true,
				dragSize: 100
			},
			breakpoints: {
				768: {
					spaceBetween: 10,
					slidesPerView: 2
				},
			},
		});

		console.log( allSliders );
		console.log( allSliders.length );
		if ( allSliders.length ) {
		allSliders.forEach(element => {
				element.on('slideChangeTransitionEnd',function(swiper, speed, internal) {
					if ( cards_people.length > 0 ) {
						cards_people.forEach(element => {
							element.classList.remove('active');
						});
						
					}
				});
			}
		);
		}else{
			allSliders.on('slideChangeTransitionEnd',function(swiper, speed, internal) {
				if ( cards_people.length > 0 ) {
					cards_people.forEach(element => {
						element.classList.remove('active');
					});
					
				}
			});
		}
		
	}
	const processCarousel =  new Swiper('.process-carousel', {
		modules: [Scrollbar],
		spaceBetween: 10,
		slidesPerView: 1,
		freeMode: true,
		loop: false,
		scrollbar: {
			el: '.process-carousel .swiper-scrollbar',
			hide: false,
			draggable: true,
			dragSize: 100
		},
		breakpoints: {
			768: {
				spaceBetween: 32,
				slidesPerView: 2
			},
			1200: {
				spaceBetween: 32,
				slidesPerView: 4
			},
			1500: {
				spaceBetween: 32,
				slidesPerView: 5
			}
		},
	});	
	

	// keep an eye on viewport size changes
    breakpoint.addEventListener('change', function (e){
        // kickstart
        breakpointChecker();
    })
    breakpointChecker();
}

export default Swipers;
