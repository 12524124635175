// import Swiper JS
import Swiper, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper';

const HomeSlider = () => {

    const home_swiper = new Swiper('.home_swiper', {
        modules: [Navigation,Pagination,EffectFade],
        slidesPerView: 1,
        effect: 'fade',
        loop : true,
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            
        },
    });

}

export default HomeSlider;