// import Swiper JS
import Swiper, { Navigation} from 'swiper';

const cardsCarousel = () => {
    new Swiper('.cards_carousel', {
        modules: [Navigation],
        spaceBetween: 32,
        slidesPerView: 1,
        navigation: {
            nextEl: '.cards_carousel .swiper-button-next',
            prevEl: '.cards_carousel .swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            1300: {
                slidesPerView: 4,
                spaceBetween: 40
            }
        }
    });
}

export default cardsCarousel;