const useCasesFilter = () => {
    let endpoint = '';
	let lang = socialidea.LANG ? socialidea.LANG : 'it';
    endpoint = '/wp-json/api/v1/usecases/?lang='+lang;
	if ( document.getElementById('useCasesFilter') ) {
		var app = new Vue({
			el: '#useCasesFilter',
			data() {
				return {
					useCases : [],
					selectedIndustry : [],
					selectedSolution : [],
					selectedTechnology : [],
					page: 1,
					perPage: 12,
					pages: [],	
					totalUseCases : '',
					loading: true,
				}
			},
			mounted(){
				fetch( endpoint )
				.then(res => res.json())
				.then(data => {
					this.useCases = data;
					this.loading = false;
				})
				
			},
			methods: {
				toggleDrop(e){
					let filter = document.querySelectorAll(".filter");				
					let isVisible = e.target.closest('.filter').classList.contains('opened');
					filter.forEach(el => {
						el.closest('.filter').classList.remove('opened');
					});	

					e.target.closest(".filter").classList.toggle('opened',!isVisible);			
					
					window.addEventListener('mouseup', function(e){   
						filter.forEach(el => {
							if (!el.contains(e.target) && el.closest(".filter").classList.contains('opened')){
								el.closest(".filter").classList.remove('opened');
							}
						});
						
					});
				},
				fromSelectFilter(selectedFilter, selectedValue, event){
					switch (selectedFilter) {
						case 'industry':
							if(event.target.checked){
								if(!this.selectedIndustry.includes(selectedValue)){
									this.selectedIndustry.push(selectedValue);
								}
							}else{
								const index = this.selectedIndustry.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedIndustry.splice(index, 1); 
								}
							}
							break;
						case 'solution':
							if(event.target.checked){
								if(!this.selectedSolution.includes(selectedValue)){
									this.selectedSolution.push(selectedValue);
								}
							}else{
								const index = this.selectedSolution.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedSolution.splice(index, 1); 
								}
							}
							break;
                        case 'technology':
							if(event.target.checked){
								if(!this.selectedTechnology.includes(selectedValue)){
									this.selectedTechnology.push(selectedValue);
								}
							}else{
								const index = this.selectedTechnology.indexOf(selectedValue);
								if (index > -1) { 
									this.selectedTechnology.splice(index, 1); 
								}
							}
							break;
						
					}
				},
				resetFilter(selectedFilter,event){
					let fiterParent = event.target.closest('.filter');
					let inputs = fiterParent.querySelectorAll('.filter__list > ul > li > input[type="checkbox"]');
					inputs.forEach(input =>{
						if(input.checked) input.checked = false;				
					})
					switch (selectedFilter) {
						case 'industry':
							this.selectedIndustry = [];
							break;
						case 'solution':
							this.selectedSolution = [];
							break;
                        case 'technology':
							this.selectedTechnology = [];
							break;
					}
				},
				highlightResult(current, filter) {
					const startIndex = current.toLowerCase().indexOf(filter.toLowerCase())
					if (startIndex >= 0) {
						const endIndex = startIndex + filter.length
						const highlightedName = current.slice(0, startIndex) + '<span class="fw-500">' + current.slice(startIndex, endIndex) + '</span>' + current.slice(endIndex)
						return highlightedName
					}
					return current
				},
				paginate (posts) {
					let page = this.page;
					let perPage = this.perPage;
					let from = (page * perPage) - perPage;
					let to = (page * perPage);
					return  posts.slice(from, to);
				},
				setPages() {
					let numberOfPages = Math.ceil(this.totalUseCases / this.perPage);
					this.pages = []; /* svuoto e ri-riempio */
					for (let index = 1; index <= numberOfPages; index++) {
						this.pages.push(index);
					}
				},
				paginationClick(event){
					console.log(event.target);
					let filterTop = document.querySelector('#useCasesFilter').offsetTop;
					if(!event.target.classList.contains('pagination-current')){
						window.scroll({
							top: filterTop, 
							left: 0,
							behavior: 'smooth'
						  });
					}
				}
			},
			computed: {
				useCasesIndustry(){
					let industryArr = [];
					this.useCases.forEach(useCase => {
						if(useCase.industry.length){
                            useCase.industry.forEach(el =>{
                                industryArr.push(el);
                            })
						};
					});
					let uniqueArray = industryArr.filter((item, index) => {
						return industryArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
                useCasesSolution(){
					let solutionArr = [];
					this.useCases.forEach(useCase => {
						if(useCase.solution.length){
                            useCase.solution.forEach(el =>{
                                solutionArr.push(el);
                            })
						};
					});
					let uniqueArray = solutionArr.filter((item, index) => {
						return solutionArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				useCasesTechnology(){
					let technologyArr = [];
					this.useCases.forEach(useCase => {
						if(useCase.technology.length){
                            useCase.technology.forEach(el =>{
                                technologyArr.push(el);
                            })
						};
					});
					let uniqueArray = technologyArr.filter((item, index) => {
						return technologyArr.findIndex(i => i.title === item.title) === index;
					});
					uniqueArray.sort((a, b) => a.title.localeCompare(b.title))
					return uniqueArray;
				},
				paginatedUseCases(){
					this.totalUseCases = this.computedUseCases.length;
					return this.paginate(this.computedUseCases);
				},
				computedUseCases(){	
					this.page = 1;								
                    if(this.selectedIndustry.length || this.selectedSolution.length || this.selectedTechnology.length){
						let arrIndustry = this.selectedIndustry.map(el => el.title)
						let arrSolution = this.selectedSolution.map(el => el.title)
						let arrTechnology = this.selectedTechnology.map(el => el.title)
                        return this.useCases.filter(el =>{
                            let arrIndustryTitle = el.industry.map(el => el.title)
                            let arrSolutionTitle = el.solution.map(el => el.title)
                            let arrTechnologyTitle = el.technology.map(el => el.title)

							let resultIndustries 	= arrIndustry.some(value => arrIndustryTitle.includes(value))
                            let resultSolutions 	= arrSolution.some(value => arrSolutionTitle.includes(value));
                            let resulTechnologies 	= arrTechnology.some(value => arrTechnologyTitle.includes(value));
                            return resultIndustries || resultSolutions || resulTechnologies
                        })
                    }else{
                        return this.useCases
                    }
				}		
			},
			watch: { 
				totalUseCases() {
					this.setPages(); /* ogni volta che totalUseCases cambia esegi setPages */
				}
			},
		})
	}
}

export default useCasesFilter;