// import Swiper JS
import Swiper, { Navigation, Pagination, Scrollbar, EffectFade, Autoplay } from 'swiper';

const IndustrySlider = () => {

    const home_swiper = new Swiper('.industry_swiper', {
        modules: [Navigation,Pagination,EffectFade],
        slidesPerView: 2.5,
        spaceBetween: 30,
    });

}

export default IndustrySlider;