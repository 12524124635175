const infiniteCarousel = () => {
    var r = document.querySelector(':root');
    var wrap = document.querySelector('.people_infinite_carousel__wrapper');
    var container = document.querySelector('.people_infinite_carousel__container');
    let lastCard = wrap.lastElementChild
    let counter = 1;
    const callback = (array) => {
        array.forEach(card => {
            if(card.isIntersecting){
                populateCards(counter)
                observer.unobserve(lastCard)
                lastCard = wrap.lastElementChild
                observer.observe(lastCard)
                counter++
            }
        })
    }

    const populateCards = (num) => {
            var clone = container.cloneNode(true)
            wrap.appendChild(clone)
            var multiContainers = document.querySelectorAll('.people_infinite_carousel__container');
            r.style.setProperty('--scrollwidth', '-'+ wrap.offsetWidth * num +'px');
            r.style.setProperty('--scrollSpeed', 10 * num +'s');
            var translate = (num - 1) * 110;
            clone.style.transform = 'translateX('+translate+'vw)';
            if(num > 2){
                wrap.firstElementChild.remove();
            }
    }

    const observer = new IntersectionObserver(callback)
    observer.observe(lastCard)

}

export default infiniteCarousel;