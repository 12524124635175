const scrollbarWidth = () => {

    var scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    var r = document.querySelector(':root');
    r.style.setProperty('--scrollbarWidth', scrollbarWidth+'px');
    document.body.removeChild(scrollDiv);

}

export default scrollbarWidth;